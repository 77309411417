import { createRouter, createWebHashHistory } from 'vue-router'

import App from './App.vue'
import ViewMiddle from './ViewMiddle.vue'

const routes = [
  {
    path: '/',
    component: ViewMiddle,
    children: [
      {
        path: '/auth',
        name: 'auth',
        component: () => import('./views/Auth.vue'),
      },
      {
        path: '/:locationId?',
        name: 'login',
        component: () => import('./views/Login.vue'),
        meta: {
          requiredLogin: false,
        },
      },
    ],
  },
  {
    path: '/',
    component: App,
    children: [
      // station
      {
        path: '/station',
        name: 'station',
        component: () => import('./views/station/Station.vue'),
        meta: {
          requiredLogin: true,
        },
      },
      {
        path: '/station/resting',
        name: 'restinglist',
        component: () => import('./views/station/Resting.vue'),
        meta: {
          requiredLogin: false,
        },
      },
      {
        path: '/station/repair',
        name: 'repair',
        component: () => import('./views/station/Repair.vue'),
        meta: {
          requiredLogin: false,
        },
      },

      // prototype
      {
        path: '/prototype',
        name: 'prototype',
        component: () => import('./views/station/Prototype.vue'),
        meta: {
          requiredLogin: true,
        },
      },

      // manufacture
      {
        path: '/manufacture/component',
        name: 'component',
        component: () => import('./views/manufacture/Component.vue'),
        meta: {
          requiredLogin: true,
        },
      },
    ],
  },

  {
    path: '/list/material/:warehouseId?',
    name: 'materiallist',
    component: () => import('./views/list/MaterialList.vue'),
    props: true,
    meta: {
      requiredLogin: false,
    },
  },
]

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.params.locationId && to.path === '/') {
    next({ name: 'login', params: { locationId: this?.$store?.state?.location.id ?? 1 } });
  }
    next();
})

export default router
